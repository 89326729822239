<template>
  <div class="loading-wrapper">
    <div v-show="homeLoading === false">
      <img class="bg-img" :src="this.infoData.backgroundImg" alt="" />
      <div class="login-logo">
        <span><img :src="this.infoData.hospitalLogo" alt="" class="logo-img" /></span>
        <span class="logo-title">{{ infoData.systemTitle }}</span>
      </div>
      <div class="login-wrap">
        <div class="ms-login">
          <div class="form-title">统一身份认证</div>
          <el-form :model="model" ref="ruleForm" label-width="0px">
            <!-- 用户名设置 -->
            <el-form-item prop="username" class="form-item">
              <el-input
                v-model="model.userCode"
                placeholder="用户名"
                prefix-icon="el-icon-user"
                @keyup.enter.native="nextFocus($event)"
              ></el-input>
            </el-form-item>
            <!-- 密码设置 -->
            <el-form-item prop="password" class="form-item">
              <el-input
                type="password"
                v-model="model.password"
                placeholder="密码"
                prefix-icon="el-icon-lock"
                @keyup.enter.native="nextFocus($event)"
              ></el-input>
            </el-form-item>
            <!-- 提交 -->
            <div class="login-btn">
              <el-button type="primary" @click="login">登录</el-button>
            </div>
          </el-form>
        </div>
        <div class="info-wrap">
          <div class="info-top">
            <div class="info-item">
              <span>Copyright @ 2023</span>
            </div>
            <div class="info-item">
              <span>{{ infoData.hospitalTitle }}</span>
            </div>
          </div>
          <div class="into-bottom">
            <span>技术支持：</span>
            <span>杭州常青藤智慧科技有限公司</span>
          </div>
          <div class="into-bottom">
            <span>version：{{ webVersion }}.{{ backVersion }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      homeLoading: true,
      model: {
        userCode: '',
        password: ''
      },
      rules: {
        username: [
          //必须输入用户名,否则会报出提示
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          //必须输入用户名,否则会报出提示
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 10, message: '密码长度必须大于六位', trigger: 'blur' }
        ]
      },
      infoData: {
        hospitalTitle: '浙江医院',
        systemTitle: '',
        backgroundImg: null,
        hospitalLogo: require('../../assets/img/logo.png')
      },
      inputDoms: null,
      backVersion: '',
      webVersion: process.env.VUE_APP_VERSION
    }
  },
  created() {
    this.getInfo()
    // this.enterLogin();
    localStorage.clear()
    // 示例
    const loading = this.$loading({
      lock: true,
      customClass: 'create-isLoading',
      text: '杭州常青藤智慧科技有限公司',
      spinner: '',
      background: 'rgba(0, 0, 0, 1)'
    })
    setTimeout(() => {
      loading.close()
    }, 1500)
  },
  mounted() {
    // 绑定事件
    window.addEventListener('keydown', this.keyDown)
    this.setScrollBar()

    // 浏览器缩放，更新ScrollBar位置
    window.addEventListener('resize', this.setScrollBar)

    // 输入框
    this.changeInputDoms()
    this.inputDoms = this.changeInputDoms()

    // 获取 messageBox DOM 后移除
    var el_message_box = document.querySelector('.el-message-box__wrapper')
    var v_modal = document.querySelector('.v-modal')
    if (el_message_box && v_modal) {
      el_message_box.parentNode.removeChild(el_message_box)
      v_modal.parentNode.removeChild(v_modal)
    }
  },
  // 销毁事件
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    changeInputDoms() {
      const inputDoms = document.querySelectorAll('.ms-login .form-item .el-input__inner')
      inputDoms.forEach((item, index) => {
        item.setAttribute('data-index', index)
      })
      return inputDoms
    },
    nextFocus(event) {
      const index = event.target.getAttribute('data-index')
      const nextIndex = parseInt(index) + 1
      const length = this.inputDoms.length
      if (nextIndex < length) {
        this.inputDoms[nextIndex].focus()
      } else {
        if (this.model.password === '') {
          this.inputDoms[0].focus()
        }
      }
    },
    setScrollBar() {
      var div = document.querySelector('.login-wrap')
      if (div) {
        div.style.height = window.innerHeight - 4 + 'px'
      }
    },
    // enterLogin() {
    //   document.onkeydown = (e) => {
    //     e = window.event || e;
    //     if (
    //       this.$route.path == "/" &&
    //       (e.code == "Enter" || e.code == "enter")
    //     ) {
    //       //调用登录事件方法
    //       this.login();
    //     }
    //   };
    // },
    keyDown(e) {
      // 如果是回车则执行登录方法
      if (this.$route.path == '/' && e.keyCode == 13) {
        // 需要执行的登录方法
        if (this.model.password !== '' && this.model.userCode !== '') {
          this.login()
        }
      }
    },
    async login() {
      const { data: res } = await this.axios.get(
        `/v1/webconsole/user/login?userCode=${this.model.userCode}&password=${this.model.password}`
      )
      if (res.status == 200) {
        localStorage.setItem('userCode', this.model.userCode)
        this.msg = this.$message({
          duration: 1000,
          type: 'success',
          message: res.msg
        })
        localStorage.setItem('name', res.data.name)
        localStorage.setItem('department', res.data.department)
        this.$api.get('/v1/webconsole/user/modules').then((res) => {
          if (res.data && res.data.data) {
            this.userModuleList = res.data.data
            const firstHome = this.userModuleList[0].modulecode
            if (firstHome === 'Solution') {
              this.$router.push('/solution')
            }
            if (firstHome === 'OperationManage') {
              this.$router.push('/Home')
            }
            if (firstHome === 'OperationScheduling') {
              this.$router.push('/OperationRoom')
            }
            if (firstHome === 'ResearchQueue') {
              this.$router.push('/ResearchQueue')
            }
            if (firstHome === 'SpecializedDisease') {
              this.$router.push('/Database')
            }
            if (firstHome === 'FollowManage') {
              this.$router.push('/FollowNav')
            }
          }
        })
      } else {
        return this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    async getInfo() {
      await this.$api.get(`/v1/webconsole/logo`).then(
        (res) => {
          this.infoData = res.data.data
          this.backVersion = res.data.data.version
          if (!this.infoData.hospitalLogo) {
            this.infoData.hospitalLogo = require('../../assets/img/logo.png')
          }
          setTimeout(() => {
            this.homeLoading = false
          }, 500)
          setTimeout(() => {
            this.$nextTick(() => {
              this.inputDoms[0].focus()
            })
          }, 600)
          document.title = res.data.data.systemTitle
          localStorage.setItem('system_title', res.data.data.systemTitle)
        },
        () => {
          setTimeout(() => {
            this.homeLoading = false
          }, 500)
          setTimeout(() => {
            this.$nextTick(() => {
              this.inputDoms[0].focus()
            })
          }, 600)

          var bgc = document.querySelector('.bg-img')
          bgc.style.background = 'rgba(0, 0, 0, .3)'
          this.infoData.backgroundImg = require('../../assets/img/background.jpg')
        }
      )
    }
  }
}
</script>

<style lang="scss">
.create-isLoading {
  .el-loading-spinner .circular {
    /*隐藏 之前  element-ui  默认的 loading 动画*/
    display: none;
  }

  .el-loading-spinner .el-loading-text {
    /*为了使得文字在loading图下面*/
    margin: 85px 0px;
  }
  .el-loading-spinner .el-loading-text {
    font-size: 16px;
  }
  .el-loading-spinner {
    /* 图片替换为你自定义的即可 */
    background: url('../../assets/img/logo.png') no-repeat;
    background-size: 77px 63px;
    width: 100%;
    height: 100px;
    background-position: center;
    top: 45%;
  }
}
.login-wrap {
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  width: 100%;
  text-align: center;
}

.login-logo {
  position: absolute;
  display: flex;
  left: 3%;
  top: 5%;
  width: 750px;
  height: 50px;
  padding: 20px 40px;
  border-radius: 18px;
  color: #fff;
  font-weight: bold;
  .logo-img {
    max-height: 50px;
    margin-right: 10px;
  }
  .logo-title {
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-size: 36px;
  }
}
.ms-login {
  position: absolute;
  right: 10%;
  top: 45%;
  width: 400px;
  height: 270px;
  margin-left: -190px;
  margin-top: -150px;
  padding: 20px 40px;
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.85);
  .form-title {
    height: 40px;
    width: 300px;
    font-size: 20px;
    font-weight: bold;
    text-align: start;
  }
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
}

.form-item {
  .el-input {
    width: 100%;
    .el-input__inner {
      height: 40px;
      color: #000;
    }
  }
}

.info-wrap {
  height: 60px;
  width: 100px;
  z-index: 10;
  width: 600px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 95%;
  transform: translate(-50%, -50%);
  color: #fff;
  .info-top {
    justify-content: center;
    height: 50px;
    display: flex;
    align-items: flex-end;
    .info-item {
      margin-right: 10px;
    }
  }
  .info-bottom {
    padding-top: 10px;
  }
  .el-divider {
    margin: 12px 0px;
  }
}

.bg-img {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 1);
}
</style>
